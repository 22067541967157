import React from "react"
import Layout from "../layouts/de"
import Section01 from "./section01/index.de"
import Intro from "./intro/index.de"
import Contact from "./contact/index.de"
import CookieConsent, { Cookies } from "react-cookie-consent";
import InfoDiv from '../components/InfoDiv';
import Section02 from "./gallery/index.de"
import Section03 from "./b2b/index.de"
import Section04 from "./swiece/index.de"

const language = 2

Cookies.get("gatsby-cookies")===null?Cookies.set('gatsby-gdpr-google-analytics', 'true'):
(
  !Cookies.get("gatsby-cookies")?
    Cookies.set('gatsby-gdpr-google-analytics', 'true'):
    Cookies.set('gatsby-gdpr-google-analytics', 'false')
)


const IndexPage = () => (
  <Layout>

  <CookieConsent
      location="bottom"
      buttonText="Akzeptieren"
      declineButtonText="Ablehnen"
      cookieName="gatsby-cookies"
      acceptOnScroll={true}
      style={{ background: "rgba(0,0,0,.8)" }}
      buttonStyle={{ background: "green", fontSize: "12px" }}
      declineButtonStyle={{background: "red",fontSize:"12px"}}
      expires={14}
      contentClasses="content"
      enableDeclineButton
      onDecline={() => {
        Cookies.remove('_ga')
        Cookies.remove('_gat')
        Cookies.remove('_gid')
        Cookies.set('gatsby-gdpr-google-analytics', 'false')
      }}
      onAccept={() => {
          Cookies.set('gatsby-gdpr-google-analytics', 'true')
      }}
  >
      Diese Seite verwendet Cookies. Indem Sie Ihre Browsereinstellungen nicht ändern, stimmen Sie deren Verwendung zu.{"  "}
      <InfoDiv element="pp" pp_info="Datenschutz-Bestimmungen" lang={language}/>
  </CookieConsent>

    <div id={`starter_div`}>

      <Intro lang={language} />

      <Section01 lang={language}/>

      <Section02 lang={language} seoImg="Alma Blumenladen Breslau" />

      <Section03 lang={language} />

      <Section04 lang={language} />

      <Contact lang={language} />

    </div>

 </Layout>
)

export default IndexPage
