import React from 'react';
import { StaticQuery,graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Image from "gatsby-image";
import Header from '../../components/header';
import { Carousel } from "react-responsive-carousel";
import HeaderName from "../../svg/vellutier.svg";

const Section02 = ({dataPack, lang, headerD= dataPack.allJson.edges[0].node.headerData[5]}) => (
  <section className={`${headerD.headerSection}`} id={`${headerD.headerLink}`}>

    <Header
      siteTitle={headerD.headerName[lang]}
      imgBg={headerD.headerImage.childImageSharp.fluid}
      imgLogo={headerD.headerLogo.childImageSharp.fluid}
      imgBgAlt={headerD.headerImgAlt[lang]}
      imgLogoAlt={headerD.headerLogoAlt[lang]}
      />

        <Container fluid >
          <Row className="screenRow textScreen equal">
            <Col lg={1} md={1} className="leftBar d-none d-md-flex flex-wrap align-content-center">
              <HeaderName />
            </Col>

            <Col lg={1} md={1} className="d-none d-sm-block">
            </Col>
            <Col lg={3} md={5} sm={8} xs={12} className="leftBarFirst d-block">
            <div className="text-horizontal"
              dangerouslySetInnerHTML={{ __html: dataPack.allMarkdownRemark.edges[0].node.excerpt}}
            />
            </Col>
            <Carousel
              className=" col carouse-bg slide-2 col-lg-8 col-md-6 col-sm-4 d-none d-md-block"
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              showArrows={false}

              infiniteLoop autoPlay
            >
            {dataPack.allMarkdownRemark.edges[0].node.frontmatter.vellutier.map(items=>(

               <Image
                alt={items.alt}
                fluid={items.pic.childImageSharp.fluid}
               />
                           ))}

            </Carousel>

          </Row>

          <Row noGutters  className="screen">
            <Col lg={4} md={6} sm={12} className="d-block">
            <Image
             alt={dataPack.allMarkdownRemark.edges[0].node.frontmatter.alt}
             fluid={dataPack.allMarkdownRemark.edges[0].node.frontmatter.imageBg.childImageSharp.fluid}
            />
            </Col>
            <Col lg={8} md={6} className="d-none d-md-block">
            </Col>

          </Row>
          <Row>
            <Col></Col>
          </Row>
        </Container>
  </section>

)

export default props =>(
<StaticQuery query={graphql`
  query candelQueryDE{
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/swiece/de/" } }
    ) {
      edges {
        node {
          excerpt(format: HTML, pruneLength: 1000)
          frontmatter {
            alt
            imageBg{
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            vellutier{
              alt
              pic{
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allJson(sort: {fields: headerData}, limit: 1) {
      edges {
        node {
          headerData{
              headerName
              headerLink
              headerSection
              headerImgAlt
              headerLogoAlt
              headerImage{
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              headerLogo{
                childImageSharp{
                  fluid(quality: 100){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
`}
render={data=><Section02 dataPack={data}{...props} />}
/>
)
