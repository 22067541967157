import React from 'react';
import PropTypes from "prop-types"
import { StaticQuery,graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Header from '../../components/header';
import Sort from '../../components/SortGallery';
import Gallery from '../../components/EnigneGallery';
const sortA = []
const thumb = []
const gallery = []

const Section02 = ({dataPack, lang, seoImg, headerD= dataPack.allJson.edges[0].node.headerData[2]}) => (
  <section className={`${headerD.headerSection}`} id={`${headerD.headerLink}`}>

    <Header
      siteTitle={headerD.headerName[lang]}
      imgBg={headerD.headerImage.childImageSharp.fluid}
      imgLogo={headerD.headerLogo.childImageSharp.fluid}
      imgBgAlt={headerD.headerImgAlt[lang]}
      imgLogoAlt={headerD.headerLogoAlt[lang]}
      />

    <Container fluid>
      <Row>
        <Col md={1} className="leftBar d-xs-none"></Col>
        <Col md={11} className="d-flex justify-content-center py-2">
          <Sort grid="react-photo-gallery--gallery" gridItem="grid-item" layoutM="fitRows" sortArray={
            dataPack.category.edges[0].node.frontmatter.gallery.map((item,i)=>(
              sortA[i] = [item.name,{filter: item.sort}]
            ))
          }/>
        </Col>
      </Row>
      <Row>
        <Col md={1} className="leftBar d-xs-none"></Col>
        <Col md={11} className="my-3" id="screen_gal">
          <Gallery mainGrid={
                dataPack.category.edges[0].node.frontmatter.grid.map((item,i)=>(
                  thumb[i] = {
                    src: item.src,
                    alt: item.main + i,
                    className: item.class,
                    width: item.width,
                    height: item.height,
                    gallery: item.main,
                    sub_gal: item.sub
                  }
                ))
            }
            gridItem= {dataPack.gallery.edges.map((gal,i)=>(
                        gallery[i]= [],
                        gal.node.frontmatter.galleryAlma.map((pic)=>(
                          pic.items.map((img,j)=>(
                            gallery[i][j] ={
                              src: img.src,
                              className: pic.class,
                              width: img.width,
                              height: img.height,
                              alt: seoImg+" - "+pic.alt[lang]+(j+1)
                            }
                          ))
                        ))
                      ))
                    }
          />
        </Col>
      </Row>
    </Container>

    <Container className="screen" fluid>
      <Row noGutters>
        <Col className="d-none d-sm-block">

        </Col>
          <Col className="d-block d-sm-none background-sm">
        </Col>
      </Row>
    </Container>
  </section>

)

export default props =>(
<StaticQuery query={graphql`
  query galleryQueryDE{
    category: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/gallery/de/" } }
    ) {
      edges {
        node {
          frontmatter {
            gallery{
              name
              sort
            }
            grid{
              src
              class
              width
              height
              main
              sub
            }
          }
        }
      }
     }
     gallery: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/gallery/container/" } }, sort: {fields: frontmatter___galleryAlma___iter, order: ASC}
      ) {
        edges {
          node {
            frontmatter {
              galleryAlma{
                class
                iter
                alt
                items{
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    allJson(sort: {fields: headerData}, limit: 1) {
      edges {
        node {
          headerData{
            headerName
            headerLink
            headerSection
            headerImgAlt
            headerLogoAlt
              headerImage{
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              headerLogo{
                childImageSharp{
                  fluid(quality: 100){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
`}
render={data=><Section02 dataPack={data}{...props} />}
/>
)
Section02.propTypes = {
  dataPack: PropTypes.string,
  lang: PropTypes.string,
  seoImg: PropTypes.string
}

Section02.defaultProps = {
  lang:'1',
  seoImg:`Kwiaciarnia Alma Wrocław`
}
